import React, { useContext } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTranslation } from 'react-i18next';

// Context
import { GameContext } from 'components/providers/GameProvider';

const ResetButton = () => {
  const { t } = useTranslation(['game', 'general']);

  const { resetGame } = useContext(GameContext);

  const cancelGame = () => {
    stats.push(['trackEvent', 'Game', 'Reset Game']);
    resetGame();
  };

  return (
    <button
      type="button"
      onClick={cancelGame}
      className="is-size-6 button has-text-weight-bold has-text-white boxshadow has-border-black has-text-shadowlight has-background-danger is-block my-4 ml-2 is-inline is-size-5 is-rounded"
    >
      {`${t('game:cancel_game')} 🗙`}
    </button>
  );
};

export default ResetButton;
