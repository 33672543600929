import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasData: false,
  id: '',
  name: '',
  playerIndex: -1,
  players: [],
  admin: null,
  amountRounds: -1,
  activeRound: -1,
  activeStory: -1,
  chapter: '',
  givenText: '',
  ready: false,
  timer: null,
  settings: {
    roundMultiplier: 1,
    timeMultiplier: -1,
    readStory: 1,
  },
  chat: [],
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    // Public functions
    setID: (state, action) => {
      state.id = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    // Functions only available via GameProvider
    _setGame: (state, action) => ({
      ...state,
      hasData: true,
      ...action.payload,
      playerIndex: action.payload.players.findIndex((player) => player.name === state.name),
    }),
    _resetGame: (state, action) => ({
      ...state,
      ...action.payload,
      chapter: '',
      givenText: '',
      ready: false,
      chat: [],
    }),
    _setReady: (state, action) => {
      state.ready = action.payload;
    },
    _setChapter: (state, action) => {
      state.chapter = action.payload;
    },
    _setRound: (state, action) => {
      state.activeRound = action.payload;
    },
    _clearRound: (state) => ({
      ...state,
      chapter: '',
      ready: false,
    }),
    _addPlayer: (state, action) => {
      state.players.push(action.payload);
    },
    _removePlayer: (state, action) => {
      const { name } = action.payload;
      state.players = state.players.filter((player) => player.name !== name);
    },
    _addChatMsg: (state, action) => {
      state.chat.push(action.payload);
    },
    _clearGame: () => initialState,
  },
});

export const {
  setName, setID,
  _addChatMsg,
  _setGame, _clearGame, _resetGame,
  _setReady,
  _setChapter,
  _setRound, _clearRound,
  _addPlayer, _removePlayer,
} = gameSlice.actions;

export default gameSlice.reducer;
