import { Howl } from 'howler';

import readyWebm from 'assets/sounds/ready.webm';
import readyMp3 from 'assets/sounds/ready.mp3';
import nextRoundWebm from 'assets/sounds/nextround.webm';
import nextRoundMp3 from 'assets/sounds/nextround.mp3';
import storyRevealWebm from 'assets/sounds/storyreveal.webm';
import storyRevealMp3 from 'assets/sounds/storyreveal.mp3';
import timesUpWebm from 'assets/sounds/timesup.webm';
import timesUpMp3 from 'assets/sounds/timesup.mp3';

const readySound = new Howl({
  src: [readyWebm, readyMp3],
});

const nextRoundSound = new Howl({
  src: [nextRoundWebm, nextRoundMp3],
});

const storyRevealSound = new Howl({
  src: [storyRevealWebm, storyRevealMp3],
});

const timesUpSound = new Howl({
  src: [timesUpWebm, timesUpMp3],
});

export {
  readySound, nextRoundSound, storyRevealSound, timesUpSound,
};
