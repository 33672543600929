import React from 'react';

// Libraries
import {
  Router,
  Route,
  Switch,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import stats from 'analytics/matomo';
import { HelmetProvider, Helmet } from 'react-helmet-async';

// Constants
import * as routes from 'constants/routes';

// Components
import Landing from './components/layout/Landing';

import GameFinder from './components/game/GameFinder';
import GameWrapper from './components/game/GameWrapper';

// Layout / basic components
import Imprint from './components/layout/Imprint';
import PrivacyPolicy from './components/layout/PrivacyPolicy';

// Initialize languages
import './locales/i18n';

const history = stats.connectToHistory(createBrowserHistory());

// stats.push(['requireConsent']);
stats.push(['trackPageView']);
stats.push(['enableHeartBeatTimer']);

const App = () => (
  <Router history={history} forceRefresh>
    <HelmetProvider>
      <Helmet>
        <title>CrazyStories</title>
      </Helmet>
      <Switch>
        <Route
          exact
          path={routes.LOBBY}
          component={() => <GameWrapper />}
        />
        <Route
          exact
          path={routes.LANDING}
          component={() => <Landing />}
        />
        <Route
          exact
          path={routes.IMPRINT}
          component={() => <Imprint />}
        />
        <Route
          exact
          path={routes.PRIVACY}
          component={() => <PrivacyPolicy />}
        />
        <Route
          path="*"
          render={() => <GameFinder />}
        />
      </Switch>
    </HelmetProvider>
  </Router>
);

export default App;
