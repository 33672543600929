import React, { useContext } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Context
import { GameContext } from 'components/providers/GameProvider';

const KickButton = (props) => {
  const { player } = props;

  const { kickPlayer } = useContext(GameContext);

  const kick = () => {
    stats.push(['trackEvent', 'Game', 'Player kicked']);
    kickPlayer(player);
  };

  return (
    <button
      type="button"
      className="cleanButton p0"
      onClick={kick}
    >
      <p className="is-inline is-size-4 ml-2">🗑️</p>
    </button>
  );
};

KickButton.propTypes = {
  player: PropTypes.string.isRequired,
};

export default KickButton;
