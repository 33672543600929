import React, { useContext, useMemo } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTranslation } from 'react-i18next';

// Context
import { GameContext } from 'components/providers/GameProvider';

// Components
import Dropdown from 'components/utils/Dropdown';

const VoiceSettings = () => {
  const { t } = useTranslation(['general']);

  const {
    player, settings, setReadStory,
  } = useContext(GameContext);

  const { readStory } = settings;

  const allOptions = useMemo(() => [
    { value: 0, label: t('game:dont_read') },
    { value: 1, label: t('game:read') },
  ], [t]);

  const currentOption = useMemo(() => {
    let label = t('game:read');

    if (readStory === 0) {
      label = t('game:dont_read');
    }

    return { label, value: readStory };
  }, [readStory, t]);

  const setOption = (newVal) => {
    stats.push(['trackEvent', 'Read Story', `Set to ${newVal}`]);
    setReadStory(newVal);
  };

  return (
    <div>
      <label className="label has-text-shadowlight has-text-white is-size-5 is-size-6-mobile">
        {t('game:read_stories')}
      </label>
      <div className="control">
        <Dropdown
          items={allOptions}
          onSelect={setOption}
          value={currentOption}
          disabled={!player.isAdmin}
        />
      </div>
    </div>
  );
};

export default VoiceSettings;
