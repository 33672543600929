import React from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Constants
import { LANDING } from 'constants/routes';

const LandingButton = () => {
  const { t } = useTranslation(['general']);

  const history = useHistory();

  const goLanding = () => {
    stats.push(['trackEvent', 'Brand Icon', 'Button clicked']);
    history.push(LANDING);
  };

  return (
    <button
      type="button"
      onClick={goLanding}
      className="button has-text-weight-bold has-text-white boxshadow has-border-black has-text-shadowlight has-background-danger is-block my-2 ml-2 is-rounded"
    >
      <i className="fas fa-caret-left is-inline mr-2" />
      <p className="is-inline">
        {t('general:leave')}
      </p>
    </button>
  );
};

export default LandingButton;
