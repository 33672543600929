import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

const Tutorial = () => {
  const { t } = useTranslation(['general', 'game']);
  return (
    <div className="box has-background-blue has-text-white br-0 has-border-black has-no-border-top">
      <div className="mb10 is-size-5 is-size-6-mobile">
        <h3 className="has-text-white has-text-shadowlight mr-1 has-text-weight-bold is-inline">1.</h3>
        <p className="has-text-white has-text-shadowlight has-text-weight-bold is-inline">
          {`${t('game:begin_instruction')} `}
        </p>
      </div>
      <div className="mb10 p10 has-border-black has-background-white br10">
        <p className="is-size-5 is-size-6-mobile has-text-black has-text-weight-semibold">
          {`${t('game:begin_example')} `}
        </p>
      </div>
      <div className="mb10 is-size-5 is-size-6-mobile">
        <h3 className="has-text-white has-text-shadowlight mr-1 has-text-weight-bold is-inline">2.</h3>
        <p className="has-text-white has-text-shadowlight has-text-weight-bold is-inline">
          {`${t('game:read_instruction')} `}
        </p>
      </div>
      <div className="mb10 p10 has-border-black has-background-white br10">
        <p className="is-size-5 is-size-6-mobile has-text-black has-text-weight-semibold">
          {`${t('game:read_example')} `}
        </p>
      </div>
      <div className="mb10 is-size-5 is-size-6-mobile">
        <h3 className="has-text-white has-text-shadowlight mr-1 has-text-weight-bold is-inline">3.</h3>
        <p className="has-text-white has-text-shadowlight has-text-weight-bold is-inline">
          {`${t('game:write_instruction')} `}
        </p>
      </div>
      <div className="mb10 p10 has-border-black has-background-white br10">
        <p className="is-size-5 is-size-6-mobile has-text-black has-text-weight-semibold">
          {`${t('game:write_example')} `}
        </p>
      </div>
      <div className="mb10 is-size-5 is-size-6-mobile">
        <h3 className="has-text-white has-text-shadowlight mr-1 has-text-weight-bold is-inline">4.</h3>
        <p className="has-text-white has-text-shadowlight has-text-weight-bold is-inline">
          {`${t('game:results_instruction')} `}
        </p>
      </div>
      <div className="mb10 p10 has-border-black has-background-white br10">
        <p className="is-size-5 is-size-6-mobile has-text-black has-text-weight-semibold">
          {`${t('game:results_example')} `}
        </p>
      </div>
    </div>
  );
};

export default Tutorial;
