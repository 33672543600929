import React, { useContext } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Context
import { GameContext } from 'components/providers/GameProvider';

const GrantAdminButton = (props) => {
  const { player } = props;

  const { player: user, setAdmin } = useContext(GameContext);

  const _grantAdmin = () => {
    if (!user.isAdmin) return;

    stats.push(['trackEvent', 'Game', 'Admin set']);
    setAdmin(player);
  };

  return (
    <button
      type="button"
      className="cleanButton p0"
      onClick={_grantAdmin}
    >
      <p className="is-inline is-size-4 ml-2">🤡</p>
    </button>
  );
};

GrantAdminButton.propTypes = {
  player: PropTypes.string.isRequired,
};

export default GrantAdminButton;
