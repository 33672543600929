import React, { useContext, useState } from 'react';

// Context
import { GameContext } from 'components/providers/GameProvider';

// Libraries
import createGif from './createGif';

const CreateGifButton = () => {
  const [disabled, setDisabled] = useState(false);

  const {
    players, activeStory,
  } = useContext(GameContext);

  if (!players || activeStory === -1) return null;

  const _createGif = async () => {
    try {
      setDisabled(true);

      const playerData = players.map((player) => {
        const { name, icon } = player;
        return {
          name,
          icon,
        };
      });

      await createGif({
        story: players[activeStory].story,
        storyIndex: activeStory,
        players: playerData,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <button
      type="button"
      onClick={_createGif}
      className={`has-text-shadowlight boxshadow has-border-black button has-text-centered is-rounded has-background-info has-border-black has-text-white is-size-5 has-text-weight-bold mb-4 ${disabled ? 'is-loading' : ''}`}
      disabled={disabled}
    >
      <i className="fas fa-film is-inline mr-2" />
      <p className="is-inline">
        GIF me
      </p>
    </button>
  );
};

export default CreateGifButton;
