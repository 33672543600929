import React, { useContext } from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Hooks
import { useTranslation } from 'react-i18next';

// Context
import { GameContext } from 'components/providers/GameProvider';

// Constants
import { MAX_PLAYERS } from 'constants/game';

// Components
import LandingButton from 'components/game/GameWrapper/Game/buttons/LandingButton';
import StartButton from './StartButton';
import InviteButton from './InviteButton';
import ReadyButton from './ReadyButton';
import Player from './Player';
import InfoWrapper from './InfoWrapper';
import LanguageSelector from './LanguageSelector';

const Lobby = () => {
  const { t } = useTranslation(['general', 'game']);

  const {
    players, player,
  } = useContext(GameContext);

  const { isAdmin } = player;

  const showPlayers = () => {
    const allPlayers = players.map((currentPlayer, index) => (
      <Player
        data={currentPlayer}
        key={index}
      />
    ));

    return (
      <div>
        { allPlayers }
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>CrazyStories - Lobby</title>
      </Helmet>
      <div className="background-image-lobby is-min-fullheight is-flex flex-direction-column">
        <div className="columns is-centered is-vcentered has-fullheight has-fullwidth has-flex-1">
          <div className="column is-10-desktop is-12-touch has-border-black-bold br-10 has-background-danger py-5 is-min-90vh">
            <div className="columns is-centered is-multiline is-mobile">
              <div className="column is-3-desktop">
                <LandingButton />
              </div>
              <div className="column is-12-touch has-text-centered mb-4">
                <h2 className="boxshadow has-text-centered has-background-white has-border-black-bold font-heading has-text-yellow has-text-shadowbold is-size-3-desktop is-size-4-tablet is-size-5-mobile is-inline-block px-6">
                  {t('lobby')}
                </h2>
              </div>
              <div className="column has-max-width-400 is-6-desktop">
                <LanguageSelector />
              </div>
            </div>
            <div className="columns is-centered">
              <div className="column is-4-desktop has-text-shadowlight">
                <div className="has-text-shadowlight has-text-weight-bold is-size-4 has-text-white has-height-500 has-overflow-y-auto has-border-black px-3 has-background-blue boxshadow">
                  <p className="is-size-4 has-text-white has-text-weight-bold my-2">
                    {`${t('game:players')} ${players.length}/${MAX_PLAYERS}`}
                  </p>
                  { showPlayers() }
                </div>
                <div className="mt-5">
                  {
                    isAdmin
                      ? (
                        <div className="mt-3 has-text-centered">
                          <InviteButton />
                          <StartButton />
                        </div>
                      )
                      : (
                        <div className="has-text-centered">
                          <ReadyButton />
                        </div>
                      )
                  }
                </div>
              </div>
              <div className="is-offset-1 column is-6">
                <InfoWrapper />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lobby;
