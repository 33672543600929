import React from 'react';
import { Helmet } from 'react-helmet-async';

const Imprint = () => (
  <>
    <Helmet>
      <title>CrazyStories - Impressum</title>
    </Helmet>
    <div className="is-fullheight">
      <div className="columns has-fullheight is-vcentered is-marginless is-centered is-mobile">
        <div className="column is-8 box br-20 px-4 py-4">
          <h1 className="has-text-centered has-text-weight-bold is-size-4">
            Impressum
          </h1>
          <p>Flowdust Online Services UG (haftungsbeschränkt)</p>
          <p>Am Bahnhof 1</p>
          <p>95632 Wunsiedel</p>
          <p className="my-4">Deutschland</p>
          <p>E-Mail: hello@flowdust.com</p>
        </div>
      </div>
    </div>
  </>
);

export default Imprint;
