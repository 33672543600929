import React, {
  useContext, useMemo, useRef,
} from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

// Context
import { GameContext } from 'components/providers/GameProvider';

// Components
import Lobby from './Lobby';
import Round from './Round';
import StoryPresentation from './StoryPresentation';

const Game = () => {
  const {
    activeRound, activeStory, loading,
  } = useContext(GameContext);

  const animationRef = useRef();

  const renderContent = () => {
    if (loading) {
      return (
        <>
          <Helmet>
            <title>CrazyStories - Loading</title>
          </Helmet>
          <div className="background-image-lobby is-fullheight">
            <h2 className="has-text-centered font-heading ml40 has-text-white has-text-shadow is-size-1-desktop is-size-2-tablet is-size-3-mobile">
              Loading
            </h2>
          </div>
        </>
      );
    }

    if (activeRound === -1) {
      return (
        <>
          <Helmet>
            <title>CrazyStories - Lobby</title>
          </Helmet>
          <Lobby />
        </>
      );
    }

    if (activeRound === 0 && activeStory !== -1) {
      return (
        <>
          <Helmet>
            <title>CrazyStories - Stories</title>
          </Helmet>
          <StoryPresentation />
        </>
      );
    }

    return (
      <>
        <Helmet>
          <title>CrazyStories - Game</title>
        </Helmet>
        <Round />
      </>
    );
  };

  const animCount = useMemo(() => (activeRound === 1 || activeRound === 0 ? 1 : 0), [activeRound]);

  return (
    <SwitchTransition>
      <CSSTransition
        classNames="fade"
        timeout={300}
        key={animCount}
        nodeRef={animationRef}
      >
        <div ref={animationRef}>
          { renderContent() }
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Game;
