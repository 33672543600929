import React, { useState, useContext, useEffect } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTranslation } from 'react-i18next';

// Context
import { GameContext } from 'components/providers/GameProvider';

const InviteButton = () => {
  const { id } = useContext(GameContext);

  const [isCopied, setIsCopied] = useState(false);

  const { t } = useTranslation(['game', 'general']);

  useEffect(() => {
    if (isCopied === false) return null;

    const timeout = setTimeout(() => setIsCopied(false), 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  const copyInviteLink = () => {
    if (!id) return;

    const link = `${window.location.origin}/${id}`;

    const { navigator } = window;

    if (!navigator || !navigator.clipboard) {
      return;
    }

    navigator.clipboard.writeText(link);

    setIsCopied(!isCopied);

    stats.push(['trackEvent', 'Lobby', 'Invite Code copied']);
  };

  return (
    <>
      <button
        className="button is-rounded has-border-black has-text-shadowlight has-text-weight-bold has-text-white boxshadow has-background-blue is-in-center my-4 is-size-5"
        type="button"
        onClick={copyInviteLink}
      >
        { isCopied
          ? `${t('general:copied')}! ✂️`
          : `${t('invite')} 👋`}
      </button>
    </>
  );
};

export default InviteButton;
