import React, { useContext, useMemo } from 'react';

import stats from 'analytics/matomo';

// Context
import { GameContext } from 'components/providers/GameProvider';

// Hooks
import { useTranslation } from 'react-i18next';

const StartButton = () => {
  const {
    players, startGame,
  } = useContext(GameContext);

  const { t } = useTranslation(['game']);

  const allPlayersReady = useMemo(() => {
    if (players.length === 1) return false;

    let allReady = true;
    players.forEach((player) => {
      if (!player.isReady) allReady = false;
    });

    return allReady;
  }, [players]);

  const _startGame = () => {
    stats.push(['trackEvent', 'Lobby', 'Game started']);
    startGame();
  };

  return (
    <>
      <button
        className="button has-text-weight-bold has-text-white boxshadow has-border-black has-text-shadowlight has-background-yellow is-in-center my-2 is-size-5 is-rounded"
        type="button"
        onClick={_startGame}
        disabled={!allPlayersReady}
      >
        {`${t('game:start')} 🚀`}
      </button>
    </>
  );
};

export default StartButton;
