import React, { useMemo, useLayoutEffect, useContext } from 'react';

// Libraries
import i18n from 'i18next';
import stats from 'analytics/matomo';
import { getCurrentLocale } from 'locales/i18n';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import Dropdown from 'components/utils/Dropdown';

// Actions
import { setLang } from 'store/session';

// Context
import { GameContext } from 'components/providers/GameProvider';

const WRITTEN_LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' },
];

const SPEAKING_LANGUAGES = [
  { label: 'English', value: 'en-US' },
  { label: 'Deutsch', value: 'de-DE' },
  // { label: 'Francais', value: 'fr-FR' },
  // { label: 'Italiano', value: 'it-IT' },
];

const LanguageSelector = () => {
  const { lang: speakingLang } = useSelector((state) => state.session);
  const { t } = useTranslation(['game']);

  const dispatch = useDispatch();

  const {
    settings,
  } = useContext(GameContext);

  const { readStory } = settings;

  useLayoutEffect(() => {
    if (speakingLang !== '') return;

    const detectedLanguage = getCurrentLocale();

    if (detectedLanguage.includes('de')) {
      dispatch(setLang('de-DE'));
      return;
    }

    dispatch(setLang('en-US'));
  }, []);

  const availableVoices = useMemo(() => {
    const langs = [];

    return SPEAKING_LANGUAGES;

    if ('speechSynthesis' in window) {
      // Get voices; add to table markup
      const voices = window.speechSynthesis.getVoices();
      console.log(voices);
      voices.forEach((voice) => {
        const supportedLang = SPEAKING_LANGUAGES.find((_lang) => _lang.value === voice.lang);

        if (supportedLang) langs.push(supportedLang);
      });
    }

    return langs;
  }, []);

  const changeWrittenLanguage = async (lang) => {
    try {
      await i18n.changeLanguage(lang);

      stats.push(['trackEvent', 'Written Language', `Set to ${lang}`]);
    } catch (e) {
      console.log(e);
    }
  };

  const changeVoice = async (lang) => {
    try {
      dispatch(setLang(lang));

      stats.push(['trackEvent', 'Speaking Language', `Set to ${lang}`]);
    } catch (e) {
      console.log(e);
    }
  };

  const getCurrentWrittenLang = () => {
    const locale = getCurrentLocale();
    const lang = WRITTEN_LANGUAGES.find((_lang) => _lang.value === locale);

    return lang;
  };

  const currentVoice = useMemo(() => {
    if (!speakingLang) return SPEAKING_LANGUAGES[0];

    const lang = SPEAKING_LANGUAGES.find((_lang) => _lang.value === speakingLang);

    return lang;
  }, [speakingLang]);

  return (
    <div className="columns">
      <div className="has-text-right column is-6">
        <p className="is-inline is-size-6 has-text-white has-text-weight-semibold has-text-right">
          {`${t('game:menu')} `}
        </p>
        <p className="is-inline is-size-4 has-text-white has-text-weight-bold has-text-right">
          💬
        </p>
        <Dropdown
          items={WRITTEN_LANGUAGES}
          onSelect={changeWrittenLanguage}
          value={getCurrentWrittenLang()}
        />
      </div>
      <div className="has-text-right column is-6">
        <p className="is-inline is-size-6 has-text-white has-text-weight-semibold has-text-right">
          {`${t('game:voice-over')} `}
        </p>
        <p className="is-inline is-size-4 has-text-white has-text-weight-bold has-text-right">
          🔊
        </p>
        <Dropdown
          items={availableVoices}
          onSelect={changeVoice}
          value={currentVoice}
          disabled={!readStory}
        />
      </div>
    </div>
  );
};

export default LanguageSelector;
