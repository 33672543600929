import React, { useContext } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Context
import { GameContext } from 'components/providers/GameProvider';

const ReadyButton = () => {
  const { t } = useTranslation(['game', 'general']);

  const { player, toggleReady } = useContext(GameContext);

  const { isReady } = player;

  return (

    isReady
      ? (
        <button
          type="button"
          className="has-border-black has-text-shadowlight button boxshadow has-background-danger has-text-weight-bold has-text-white is-in-center is-size-5 is-size-6-touch is-rounded "
          onClick={toggleReady}
        >
          {t('game:not_ready')}
          <i className="has-text-shadowlight ml-2 fas fa-times" />
        </button>
      )
      : (
        <button
          type="button"
          className="has-border-black has-text-shadowlight button boxshadow has-background-success has-text-weight-bold has-text-white is-size-5 is-size-6-touch is-rounded"
          onClick={toggleReady}
        >
          {t('game:ready')}
          <i className="has-text-shadowlight ml-2 fas fa-check" />

        </button>
      )

  );
};

export default ReadyButton;
