import React from 'react';

// Libraries
import { Link } from 'react-router-dom';

// constants
import * as routes from 'constants/routes';

// styles
import styles from './styles.module.scss';

const Footer = () => (
  <div id={styles.footer} className="has-border-black columns is-marginless background-image-invite has-text-shadowlight has-text-yellow is-mobile is-multiline">
    <div className="column has-text-weight-bold is-12-mobile">
      <p>
        © 2021 - CrazyStories
      </p>
    </div>
    <div className="column is-narrow has-text-right has-text-centered-mobile is-6-mobile">
      <Link
        className="px-2 has-text-weight-bold has-text-yellow"
        to={routes.PRIVACY}
      >
        Datenschutz
      </Link>
    </div>
    <div className="column is-narrow has-text-right has-text-centered-mobile is-6-mobile">
      <Link
        className="px-2 has-text-weight-bold has-text-yellow"
        to={routes.IMPRINT}
      >
        Impressum
      </Link>
    </div>
  </div>
);

export default Footer;
