import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from './session';
import gameReducer from './game';

export default configureStore({
  reducer: {
    session: sessionReducer,
    game: gameReducer,
  },
});
