import React, { useContext, useState, useEffect } from 'react';

// Context
import { GameContext } from 'components/providers/GameProvider';

// Sounds
import {
  timesUpSound,
} from 'sounds';

const Timer = () => {
  const { timer, activeRound } = useContext(GameContext);

  const calculateTimeLeft = () => {
    const difference = +new Date(timer) - +new Date();
    const secondsLeft = Math.floor((difference / 1000));
    return secondsLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeLeft(calculateTimeLeft());
  }, [activeRound]);

  useEffect(() => {
    if (timeLeft <= 0) return null;

    /* if (timeLeft === 10) {
      timesUpSound.play();
    } */

    const timeout = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [timer, timeLeft]);

  return (
    <>
      <div className="columns level-right">
        <div className=" mr-2 has-background-white has-border-black has-text-centered column is-1">
          <h3 className="is-size-3 has-text-yellow has-text-shadowbold ">
            {timeLeft}
          </h3>
        </div>
      </div>
    </>
  );
};

export default Timer;
