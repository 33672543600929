import React, { useContext, useMemo } from 'react';

// Context
import { GameContext } from 'components/providers/GameProvider';

// Hooks
import { useTranslation } from 'react-i18next';

const PlayerDisplay = () => {
  const { t } = useTranslation(['game']);

  const { players, player: me } = useContext(GameContext);

  const amountPlayersReady = useMemo(() => {
    let amountReady = 0;

    players.forEach((currentPlayer) => {
      if (currentPlayer.isReady) amountReady++;
    });

    return amountReady;
  }, [players]);

  const renderedPlayers = useMemo(() => players.map((player) => {
    const {
      name, isReady, isConnected,
    } = player;

    const isMe = player.name === me.name;

    return (
      <div className="level-right column is-narrow" key={name}>
        <div className="has-border-black has-background-danger px-2 py-2">
          <p className="is-uppercase mr-1 has-text-shadowbold is-size-4 has-text-weight-bold has-text-white is-inline">
            {`${name}`}
          </p>
          <div className="mr-1 is-inline ml-2 has-text-right is-size-5">
            {isReady
              ? '✔️'
              : '⏳'}
            { isMe && <div className="ml-1 is-inline">🧑</div>}
            {!isConnected
                && <div className="ml-1 is-inline">⚠️</div>}
          </div>
        </div>
      </div>
    );
  }), [players]);

  return (
    <h3 className="has-text-shadowbold is-size-3 has-text-yellow has-text-weight-bold mt-4 px-2">
      {`${t('game:players_ready')}: ${amountPlayersReady}/${players.length}`}
    </h3>

  );
};

export default PlayerDisplay;
