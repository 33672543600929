import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import Select from 'react-select';

// Styles
import styles from './styles.module.scss';

/**
 * Dropdown Component to select an item from a list
 */
const Dropdown = (props) => {
  const {
    items, onSelect, value, disabled,
  } = props;

  const onChange = (data) => {
    onSelect(data.value);
  };

  return (
    <Select
      value={value}
      options={items}
      onChange={onChange}
      isDisabled={disabled}
      className={styles.dropdown}
      isSearchable={false}
    />
  );
};

Dropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.node.isRequired,
  })).isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.node.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  disabled: false,
};

export default Dropdown;
