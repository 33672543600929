import React from 'react';

// Hooks
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import NameCreator from 'components/game/NameCreator';
import Footer from 'components/layout/Footer';
import ExplosionBubble from 'components/utils/ExplosionBubble';
import DiscordButton from 'components/utils/DiscordButton';
import SupportUsButton from 'components/utils/SupportUsButton';

// Constants
import { LOBBY } from 'constants/routes';

// Images
import test from 'assets/images/test.jpg';

// Styles
import styles from './styles.module.scss';

// window.history.replaceState(null, "New Page Title", "/pathname/goes/here")
const Landing = () => {
  const { t } = useTranslation(['general', 'game']);

  const { name } = useSelector((state) => state.game);

  const history = useHistory();

  const createLobby = () => {
    if (name.length < 3) return;

    if (history.location.pathname === LOBBY) {
      console.log('Already in Lobby?');
      window.location.reload();
    }

    // history.push('/');
    history.push(LOBBY);
  };

  return (
    <>
      <div className="background-image is-min-fullheight pb-6">
        <div className="columns">
          <div className="column is-3">
            <SupportUsButton />
          </div>
          <div className="column">
            <h1 className=" pt-4 has-text-centered font-heading has-text-white has-text-shadow is-size-1-desktop is-size-2-tablet is-size-5-mobile">
              CrazyStories
            </h1>
          </div>
          <div className="column is-3 has-text-right">
            <DiscordButton />
          </div>
        </div>
        <h2 className=" mb-3 has-text-centered has-text-shadowbold is-size-3 is-size-4-mobile has-text-yellow has-text-weight-light ">The game to create memories with your friends</h2>
        <div className="columns is-centered">
          <div className="br10 has-border-black-bold is-3 column has-background-white mt-6 is-relative">
            <div id={styles.bubble} className="is-hidden-mobile">
              <ExplosionBubble text="Boom" />
            </div>
            <img className="br10 has-border-black-bold " src={test} alt="" />
          </div>
        </div>
        <p className="has-text-white has-text-centered is-size-3 is-size-5-mobile has-text-weight-bold has-text-shadowbold mt-4">
          {t('general:enter_name_enter_lobby')}
        </p>
        <div className="has-text-centered mt-4">
          <NameCreator onEnter={createLobby} />
          <div className="has-text-centered mt-3">
            <button
              type="button"
              disabled={name.length < 3}
              className="button has-text-weight-bold has-background-success is-size-5 has-border-black has-text-white is-rounded boxshadow"
              onClick={createLobby}
            >
              <p className="is-inline">
                {`${t('general:start')} 🎉`}
              </p>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Landing;
