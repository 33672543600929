import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import RoundsSettings from './RoundsSettings';
import TimerSettings from './TimerSettings';
import VoiceSettings from './VoiceSettings';

const Settings = () => {
  const { t } = useTranslation(['game']);

  return (
    <div className="box has-background-yellow has-text-white has-border-black has-no-border-top br-0">
      <div className="columns mb-2 is-size-5">
        <div className="column is-6">
          <RoundsSettings />
        </div>
        <div className="column is-6">
          <TimerSettings />
        </div>
      </div>
      <div className="columns mb-2 is-size-5">
        <div className="column is-6">
          <VoiceSettings />
        </div>
      </div>
      <div className="has-text-centered mt-3">
        <p
          className="has-text-black has-text-weight-bold"
        >
          {`${t('game:more_settings_coming_soon')}! 👀`}
        </p>
      </div>
    </div>
  );
};

export default Settings;
