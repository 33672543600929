import React, { useContext, useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Context
import { GameContext } from 'components/providers/GameProvider';

// Components
import Dropdown from 'components/utils/Dropdown';

const RoundsSettings = () => {
  const { t } = useTranslation(['game']);

  const {
    settings, setRoundMultiplier, player,
  } = useContext(GameContext);

  const { roundMultiplier } = settings;

  const validRounds = useMemo(() => [
    { value: 1, label: t('game:normal') },
    { value: 2, label: t('game:double') },
    { value: 3, label: t('game:triple') },
    { value: 4, label: t('game:fourfold') },
  ], [t]);

  const value = useMemo(() => {
    let name = '';
    switch (roundMultiplier) {
      case 2: name = t('game:double');
        break;
      case 3: name = t('game:triple');
        break;
      case 4: name = t('game:fourfold');
        break;
      default: name = t('game:normal');
    }

    return { label: name, value: roundMultiplier };
  }, [roundMultiplier, t]);

  const setRounds = (amount) => {
    setRoundMultiplier(parseFloat(amount, 10));
  };

  return (
    <div>
      <label className="label has-text-shadowlight has-text-white is-size-5 is-size-6-mobile">
        {t('game:amount_rounds')}
      </label>
      <div className="control">
        <Dropdown
          items={validRounds}
          onSelect={setRounds}
          value={value}
          disabled={!player.isAdmin}
        />
      </div>
    </div>
  );
};

export default RoundsSettings;
