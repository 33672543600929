import first from 'assets/images/icons/1.png';
import second from 'assets/images/icons/2.png';
import third from 'assets/images/icons/3.png';
import fourth from 'assets/images/icons/4.png';
import fifth from 'assets/images/icons/5.png';
import sixth from 'assets/images/icons/6.png';
import seventh from 'assets/images/icons/7.png';
import eighth from 'assets/images/icons/8.png';
import ninth from 'assets/images/icons/9.png';
import tenth from 'assets/images/icons/10.png';
import eleventh from 'assets/images/icons/11.png';
import twelfth from 'assets/images/icons/12.png';
import thirteenth from 'assets/images/icons/13.png';
import fourteenth from 'assets/images/icons/14.png';
import fifteenth from 'assets/images/icons/15.png';
import sixteenth from 'assets/images/icons/16.png';
import seventeenth from 'assets/images/icons/17.png';
import eighteenth from 'assets/images/icons/18.png';
import nineteenth from 'assets/images/icons/19.png';
import twentieth from 'assets/images/icons/20.png';
import twentyfirst from 'assets/images/icons/21.png';

const icons = [
  first,
  second,
  third,
  fourth,
  fifth,
  sixth,
  seventh,
  eighth,
  ninth,
  tenth,
  eleventh,
  twelfth,
  thirteenth,
  fourteenth,
  fifteenth,
  sixteenth,
  seventeenth,
  eighteenth,
  nineteenth,
  twentieth,
  twentyfirst,
];

const getIcon = (index) => icons[index % icons.length];

export { getIcon };
export default icons;
