import React, { useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Settings from './Settings';
import Tutorial from './Tutorial';
import Chat from './Chat';

const InfoWrapper = () => {
  const { t } = useTranslation(['game']);

  const [page, setPage] = useState(0);

  const showCurrentPage = () => {
    switch (page) {
      case 1: return <Settings />;
      case 2: return <Tutorial />;
      default: return <Chat />;
    }
  };

  return (
    <div className="">
      <div className="columns is-mobile">
        <div
          className="column is-4 is-paddingless has-border-black has-no-border-right has-no-border-bottom has-background-danger"
        >
          <button
            type="button"
            className="cleanButton has-text-shadowlight has-text-white has-text-weight-bold is-block has-fullheight has-fullwidth px-3 py-3 is-size-5 is-size-6-mobile"
            onClick={() => setPage(0)}
          >
            {`${t('game:chat')} ⚙️`}
          </button>
        </div>
        <div
          className="column is-4 is-paddingless has-border-black has-no-border-bottom has-background-yellow"
        >
          <button
            type="button"
            className="cleanButton has-text-shadowlight has-text-white has-text-weight-bold is-block has-fullheight has-fullwidth px-3 py-3 is-size-5 is-size-6-mobile"
            onClick={() => setPage(1)}
          >
            {`${t('game:settings')} ⚙️`}
          </button>
        </div>
        <div
          className="column is-4 is-paddingless has-border-black has-no-border-left has-no-border-bottom has-background-blue"
        >
          <button
            type="button"
            className="cleanButton has-text-shadowlight has-text-white has-text-weight-bold is-block has-fullheight has-fullwidth px-3 py-3 is-size-5 is-size-6-mobile"
            onClick={() => setPage(2)}
          >
            {`${t('game:how_to_play')} 📙`}
          </button>
        </div>
      </div>
      { showCurrentPage() }
    </div>
  );
};

export default InfoWrapper;
