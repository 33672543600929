import React, { useContext, useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Context
import { GameContext } from 'components/providers/GameProvider';

// Components
import Dropdown from 'components/utils/Dropdown';

const TimerSettings = () => {
  const { t } = useTranslation(['game']);

  const {
    settings, player, setTimeMultiplier,
  } = useContext(GameContext);

  const { timeMultiplier } = settings;

  const timers = useMemo(() => [
    { value: -1, label: t('game:no_timer') },
    { value: 0, label: t('game:slow_timer') },
    { value: 1, label: t('game:normal_timer') },
    { value: 2, label: t('game:fast_timer') },
  ], [t]);

  const value = useMemo(() => {
    let name = '';
    switch (timeMultiplier) {
      case 0: name = t('game:slow_timer');
        break;
      case 1: name = t('game:normal_timer');
        break;
      case 2: name = t('game:fast_timer');
        break;
      default: name = t('game:no_timer');
    }

    return { label: name, value: timeMultiplier };
  }, [timeMultiplier, t]);

  const setTime = (amount) => {
    setTimeMultiplier(parseInt(amount, 10));
  };

  return (
    <div>
      <label className="label has-text-shadowlight has-text-white is-size-5 is-size-6-mobile">
        {t('game:timer')}
      </label>
      <div className="control">
        <Dropdown
          items={timers}
          onSelect={setTime}
          value={value}
          disabled={!player.isAdmin}
        />
      </div>
    </div>
  );
};

export default TimerSettings;
