import React, { useContext } from 'react';

// Libraries
import PropTypes from 'prop-types';
import { getIcon } from 'utils/icons';

// Context
import { GameContext } from 'components/providers/GameProvider';

// Components
import KickButton from 'components/game/GameWrapper/Game/buttons/KickButton';
import GrantAdminButton from '../GrantAdminButton';

// Styles
import styles from './styles.module.scss';

const Player = (props) => {
  const { data } = props;

  const { player, changeIcon } = useContext(GameContext);

  const { isAdmin: isUserAdmin } = player;

  const {
    name, isReady, isAdmin, isConnected, icon,
  } = data;

  const isMe = player.name === name;

  return (
    <div className="">
      <div className="has-border-black my-3 px-2 py-3 has-background-yellow">
        <div className="is-flex">
          <div className="is-relative">
            <img className="has-max-width-64 has-border-black br-100" src={getIcon(icon)} alt="" />
            {
              isMe
              && (
              <button
                type="button"
                id={styles.swapIconBtn}
                onClick={changeIcon}
              >
                <i className="fas fa-sync has-text-info" />
              </button>
              )
            }
          </div>
          <div className="mx-4">
            <p className="has-text-weight-bold has-text-white has-text-shadowlight">
              {name}
            </p>
          </div>
          <div className="has-text-right has-margin-left-auto">
            { isAdmin
                  && <div className="ml-2 is-inline">👑</div>}
            { isMe
                    && <div className="ml-2 is-inline">🧑</div>}
            { !isConnected
                  && <div className="ml-2 is-inline">⚠️</div>}
            {isReady
              ? <div className="ml-2 is-inline">✔️</div>
              : <div className="ml-2 is-inline">❌</div>}
            { isUserAdmin && !isMe
               && (
               <KickButton player={name} />
               )}
            {
              isUserAdmin && !isMe && isConnected
              && (
              <GrantAdminButton player={name} />
              )
            }
          </div>
        </div>

      </div>
    </div>

  );
};

Player.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.number.isRequired,
    isReady: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isConnected: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Player;
