import React, {
  useEffect, useState, useContext, useRef,
} from 'react';

// Libraries
import { getIcon } from 'utils/icons';

// Context
import { GameContext } from 'components/providers/GameProvider';

// Hooks
import { useTranslation } from 'react-i18next';

const Chat = () => {
  const { t } = useTranslation(['game']);

  const chatRef = useRef();

  const [msgToSend, setMsgToSend] = useState('');

  const {
    player, chat, sendChatMsg,
  } = useContext(GameContext);

  useEffect(() => {
    if (!chatRef.current) return;

    chatRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [chat]);

  const renderMessages = () => chat.map((msgObj, index) => {
    const { msg, author, icon } = msgObj;

    return (
      <div key={index} className={`mt-3 br-100 is-flex has-items-centered has-text-black ${player.name === author ? 'has-items-end' : ''}`}>
        <img
          className="has-max-width-32 has-border-black br-100"
          src={getIcon(icon)}
          alt=""
        />
        <p className="mx-2 has-text-weight-bold">
          {`${author}:`}
        </p>
        <p className="break-word">
          {msg}
        </p>
      </div>
    );
  });

  const sendMessage = (e) => {
    if (e.key !== 'Enter' || msgToSend.length === 0) {
      return;
    }

    sendChatMsg(msgToSend);
    setMsgToSend('');
  };

  return (

    <div className="box has-background-danger has-text-white br-0 has-border-black has-no-border-top">
      <div
        className="mb-3 p-4 has-border-black has-background-white has-overflow-y-auto has-height-350"
      >
        { renderMessages() }
        <div ref={chatRef} />
      </div>
      <div>
        <input
          type="text"
          className="input has-border-black"
          value={msgToSend}
          placeholder={`${t('game:write_a_message')}...`}
          onKeyDown={(e) => sendMessage(e)}
          onChange={(e) => setMsgToSend(e.target.value)}
        />
      </div>
    </div>
  );
};

export default Chat;
