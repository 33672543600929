import React from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useTranslation } from 'react-i18next';

// Styles
import styles from './styles.module.scss';

const DiscordButton = () => {
  const { t } = useTranslation(['general']);

  const clickDiscordButton = () => {
    stats.push(['trackEvent', 'Discord Button', 'Clicked']);
  };

  return (
    <a
      href="https://discord.gg/FSzsjRjbHz"
      onClick={clickDiscordButton}
      id={styles.button}
      target="_blank"
      className="grow"
      rel="noreferrer"
    >
      {`${t('general:join_discord')}!`}
      <i className="fab fa-discord ml-2" />
    </a>
  );
};

export default DiscordButton;
