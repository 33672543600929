import React, {
  useState, useEffect, useContext, useRef, useMemo, useLayoutEffect,
} from 'react';

// Libraries
import { SwitchTransition, TransitionGroup, CSSTransition } from 'react-transition-group';
// import { getCurrentLocale } from 'locales/i18n';
import { getIcon } from 'utils/icons';

// Context
import { GameContext } from 'components/providers/GameProvider';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components
import LandingButton from 'components/game/GameWrapper/Game/buttons/LandingButton';
import ReadyButton from 'components/game/GameWrapper/Game/buttons/ReadyButton';
import CreateGifButton from 'components/game/GameWrapper/Game/buttons/CreateGifButton';
import PlayerDisplay from '../PlayerDisplay';

import styles from './styles.module.scss';

const StoryPresentation = () => {
  const { t } = useTranslation(['general', 'game']);

  const { lang } = useSelector((state) => state.session);

  const {
    players, activeStory, player, settings,
  } = useContext(GameContext);

  const { readStory } = settings;
  const { isReady } = player;

  const storyRef = useRef();
  const wrapperRef = useRef();
  const bottomRef = useRef();

  const [activeChapter, setActiveChapter] = useState(0);
  const [isStoryDelayActive, setIsStoryDelayActive] = useState(true);

  const speaker = useMemo(() => new window.SpeechSynthesisUtterance(), []);

  const activePlayer = players[activeStory] || { name: '', story: '' };
  const { name, story } = activePlayer;

  const showCompleteStory = () => {
    if (!story) return;
    console.log('Showing complete story');

    setIsStoryDelayActive(false);
    setActiveChapter(story.length - 1);
  };

  const saveStory = () => {
    const element = document.createElement('a');

    /*eslint-disable*/
    let completeStory = `
 #####                              #####
#     # #####    ##   ###### #   # #     # #####  ####  #####  #   #
#       #    #  #  #      #   # #  #         #   #    # #    #  # #
#       #    # #    #    #     #    #####    #   #    # #    #   #
#       #####  ######   #      #         #   #   #    # #####    #
#     # #   #  #    #  #       #   #     #   #   #    # #   #    #
 #####  #    # #    # ######   #    #####    #    ####  #    #   #
\n`;

    for (let i = 0; i < story.length; i++) {
      const chapterIndex = activeStory - (i % players.length) + players.length;

      const { name: author } = players[chapterIndex % players.length];

      completeStory += `${author}: ${story[i]}\n\n`;
    }

    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(completeStory)}`);

    const time = new Date().toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit' }).replace(/:/g, '');

     const gameID = window.location.pathname.substring(1);

     const name = `CrazyStory at ${gameID} in ${time}`;

    element.setAttribute('download', name);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  useEffect(() => {
    if (isReady) {
      showCompleteStory();
    }
  }, [isReady]);

  useLayoutEffect(() => {
    setIsStoryDelayActive(true);
    setActiveChapter(0);


    const timeout = setTimeout(() => {
      setIsStoryDelayActive(false);
    }, 750);

    return () => {
      clearTimeout(timeout);
    }
  }, [activeStory]);

  useEffect(() => {
    if (!story || isStoryDelayActive) return null;

    const noVoice = !speaker || 'speechSynthesis' in window === false || !readStory;

    let timeout;
    // if voice is not supported or readStory is off, just show the animation
    if (noVoice) {
      // calculate how much time it takes to read the chapter
      const duration = Math.max(2000, parseInt(story[activeChapter].split(/\W+/).length / 3, 10) * 1000);

      // after each duration, show the next chapter
      timeout = setTimeout(() => {
        if (activeChapter + 1 < story.length) {
          setActiveChapter(activeChapter + 1);
        }
      }, duration);

      return () => {
        clearTimeout(timeout);
      };
    }

    // const speaker = new window.SpeechSynthesisUtterance();
    speaker.text = story[activeChapter];
    speaker.lang = lang;

    speaker.onend = () => {
      if (activeChapter + 1 < story.length) {
        setActiveChapter(activeChapter + 1);
      } else {
        speaker.text = t('game:the_end');
        speaker.onend = null;

        const speech = window.speechSynthesis;
        speech.speak(speaker);
      }
    };

    const speech = window.speechSynthesis;
    speech.speak(speaker);

    // scroll to bottom
    if (bottomRef) {
      bottomRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }

    return () => {
      speech.cancel();
    };
  }, [activeChapter, isStoryDelayActive]);

  if (!activeStory < 0 || !story) return null;

  const showStory = () => {
    if(isStoryDelayActive) return;

    const currentStory = story.slice(0, activeChapter + 1);

    return currentStory.map((chapter, index) => {
      const chapterIndex = activeStory - (index % players.length) + players.length;

      const { name: author, icon } = players[chapterIndex % players.length];

      const isUneven = index % 2 === 1;

      const background = isUneven ? 'has-background-info' : 'has-background-danger';
      const position = isUneven ? 'has-content-end' : '';

      const user = (
        <div className="column is-narrow has-text-centered">
          <div className="has-text-centered has-background-white has-border-black px-2 py-2 has-text-centered">
            <div className="">
              <img className="has-max-width-64 has-border-black br-100" src={getIcon(icon)} alt="" />
            </div>
            <p className="has-text-weight-bold has-text-black break-word">
              { `${author}` }
            </p>
          </div>
        </div>
      );

      return (
        <CSSTransition
          classNames="fade"
          timeout={300}
          key={index}
          ref={storyRef}
        >
          <div
            className={`box has-border-black mb-4 ${background}`}
            ref={storyRef}
          >
            <div
              className={`columns is-size-4 has-text-white ${position}`}
            >
              { !isUneven && user }
              <div className={`column has-overflow-hidden ${isUneven ? 'has-text-right' : 'has-text-left'}`}>
                <p className="has-text-shadowbold is-inline has-text-weight-semibold break-word">
                  {chapter}
                </p>
              </div>
              { isUneven && user }
            </div>
          </div>
        </CSSTransition>
      );
    });
  };

  return (
    <div
      id={styles.container}
      className="background-image-stories is-fullheight is-flex flex-direction-column"
      >
         <div className="columns">
              <div className="column is-3">
                   <LandingButton />
              </div>
              <div className="column has-text-centered">
                   <h2 className="is-inline has-text-centered font-heading has-text-white has-text-shadow is-size-1-desktop is-size-2-tablet is-size-3-mobile">
                     Stories<
                     /h2>
              </div>
              <div className="column is-3" />
         </div>
      <div id={styles.storyWrapper} className="columns is-centered has-flex-1 is-mobile">
        <div className="pt10 has-text-centered column py-0 background-image-storybox br-10 has-border-black-bold is-8-desktop is-11-touch has-overflow-y-auto is-relative">
          <div className="columns is-mobile">
            <div className="column has-text-left">
              <h3 className="is-uppercase has-text-shadowbold has-text-yellow is-inline has-text-centered  has-text-weight-semibold is-size-3 has-text-white is-size-1-desktop is-size-4-tablet is-size-4-mobile">
                {`${name}s Story` }
              </h3>
            </div>
          </div>
          <SwitchTransition>
            <CSSTransition
              classNames="fade"
              timeout={300}
              key={activeStory}
              nodeRef={wrapperRef}
            >
              <div ref={wrapperRef}>
                <TransitionGroup component={null}>
                  {showStory()}
                </TransitionGroup>
              </div>
            </CSSTransition>
          </SwitchTransition>
          {
          story && activeChapter === story.length - 1
          && (
            <>
              <div className="has-text-black mb-4">
                <h3 className="is-inline-block has-background-white has-border-black has-text-yellow has-text-shadowbold is-size-3 px-3 py-3">
                  {t('game:the_end')}
                </h3>
              </div>
              <CreateGifButton />
              <button
                type="button"
                onClick={saveStory}
                className="has-text-shadowlight boxshadow has-border-black button has-text-centered is-rounded has-background-info has-border-black has-text-white is-size-5 has-text-weight-bold mb-4 mx-4"
              >
                <i className="fas fa-file-alt is-inline mr-2" />
                <p className="is-inline">
                  TXT me
                </p>
              </button>
              <div className="is-relative">
                <div id={styles.roundInfo}>
                  <h3 className="has-text-shadowbold has-text-yellow is-inline has-text-centered  has-text-weight-semibold is-size-3-desktop is-size-4-touch">
                    {`${t('game:round')}: ${Math.max(1, activeStory + 1)}/${Math.max(1, players.length)}`}
                  </h3>
                </div>
              </div>
            </>
          )
        }
        <div id={styles.bottom} ref={bottomRef} />
        </div>
      </div>
      <div className="mt-5 has-text-centered">
        {
          activeChapter < story.length - 1
            ? (
              <button
                type="button"
                onClick={showCompleteStory}
                className="has-text-shadowlight boxshadow has-border-black button has-text-centered is-rounded has-background-info has-border-black has-text-white is-size-5 has-text-weight-bold mx-2"
              >
                <i className="fas fa-forward is-inline mr-2" />
                <p className="is-inline">
                  {t('game:skip')}
                </p>
              </button>
            )
            : <ReadyButton />
        }
      </div>
      <div className="has-flex-1 is-flex has-content-end px-3">
        <PlayerDisplay />
      </div>
    </div>
  );
};

export default StoryPresentation;
