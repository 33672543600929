import React, { useContext } from 'react';

// Context
import { GameContext } from 'components/providers/GameProvider';

// Constants
import { STORY_MAX_LENGTH } from 'constants/game';

// Hooks
import { useTranslation } from 'react-i18next';

const ReadyButton = () => {
  const { t } = useTranslation(['general', 'game']);

  const {
    activeRound, toggleReady, player, chapter,
  } = useContext(GameContext);
  const { isReady } = player;

  const setReady = () => {
    if (activeRound === 0 || activeRound === -1 || isReady) {
      toggleReady();
      return;
    }

    if (chapter.length === 0 || chapter.length > STORY_MAX_LENGTH) return;

    toggleReady();
  };

  return (
    <button
      type="button"
      className={`has-text-shadowlight boxshadow has-border-black button has-text-centered is-rounded has-border-black has-text-white is-size-5 has-text-weight-bold ${isReady ? 'has-background-danger' : 'has-background-success'}`}
      onClick={setReady}
    >
      {
        isReady
          ? (
            <>
              <i className="has-text-shadowlight mr10 fas fa-times" />
              {t('game:not_ready')}
            </>
          )
          : (
            <>
              <i className="has-text-shadowlight mr10 fas fa-check" />
              {t('game:ready')}
            </>
          )
      }
    </button>
  );
};

export default ReadyButton;
