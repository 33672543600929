import React, { useContext } from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Context
import GameProvider from 'components/providers/GameProvider';

// Components
import Game from './Game';

/**
 * Provides the context for the game
 */
const GameWrapper = () => (
  <GameProvider>
    <Game />
  </GameProvider>
);

export default GameWrapper;
