import React, { useEffect } from 'react';

// Hooks
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setID } from 'store/game';

// Constants
import { LANDING, LOBBY } from 'constants/routes';

// Components
import test from 'assets/images/test.jpg';
import DiscordButton from 'components/utils/DiscordButton';
import NameCreator from '../NameCreator';

// Images

const GameFinder = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { name } = useSelector((state) => state.game);

  const { t } = useTranslation(['game', 'general']);

  useEffect(() => {
    const { pathname } = location;

    if (!pathname) {
      history.push(LANDING);
    }

    dispatch(setID(pathname.replace('/', '')));

    if (name.length >= 3) {
      history.push(LOBBY);
    }
  }, []);

  const joinGame = () => {
    if (name.length < 3) return;

    history.push(LOBBY);
  };

  return (
    <div className="is-min-fullheight background-image-newinvite is-flex flex-direction-column">
      <div className="has-text-right mx-3 mt-3">
        <DiscordButton />
      </div>
      <div className="columns is-vcentered is-centered has-flex-1">
        <div className="column is-8 box has-border-black-bold has-background-yellow ">
          <p className="mb-3 has-text-centered pt-4 is-size-1 has-text-weight-bold has-text-white has-text-shadow">
            {t('player_got_invited')}
          </p>

          <div className="columns pb20 has-border-black-bold has-background-blue is-centered">
            <div className="column is-paddingless has-text-centered">
              <p className="has-text-white has-text-centered is-size-3 has-text-weight-bold has-text-shadowlight mt-4">
                {t('general:enter_name_enter_game')}
              </p>
              <div className="has-text-centered mt-4">
                <NameCreator onEnter={joinGame} />
                <div className="has-text-centered mt-3">
                  <button
                    type="button"
                    disabled={name.length < 3}
                    className="button has-text-weight-bold has-background-success is-size-5 has-border-black has-text-white is-rounded boxshadow"
                    onClick={joinGame}
                  >
                    {`${t('general:start')} 🎉`}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameFinder;
