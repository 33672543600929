import React from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setName } from 'store/game';

const NameCreator = (props) => {
  const { onEnter } = props;

  const { name } = useSelector((state) => state.game);
  const dispatch = useDispatch();

  const setUser = (e) => {
    const { value } = e.target;

    if (value.length > 20) {
      stats.push(['trackEvent', 'Lobby', 'Name too long']);
      return;
    }

    dispatch(setName(value));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onEnter();
    }
  };

  return (
    <input
      type="text"
      onChange={setUser}
      onKeyDown={handleKeyDown}
      value={name}
      placeholder="Leeroy Jenkins"
      className="input has-width-300 is-size-5 has-text-black has-text-weight-bold has-border-black"
    />
  );
};

NameCreator.propTypes = {
  onEnter: PropTypes.func,
};

NameCreator.defaultProps = {
  onEnter: () => {},
};

export default NameCreator;
