// Libraries
import GIF from 'gif.js';
import { getIcon } from 'utils/icons';
import backgroundImg from 'assets/images/backgrounds/gif_background.png';
import script from './gif-worker';

const GIF_WIDTH = 1200;
const GIF_HEIGHT = 630;

const PROFILE_HEIGHT = 80;

// see https://jsfiddle.net/koldev/cW7W5/
const downloadGif = (gif) => {
  const element = document.createElement('a');
  element.style = 'display: none';
  element.href = window.URL.createObjectURL(gif);

  const time = new Date().toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit' }).replace(/:/g, '');

  const gameID = window.location.pathname.substring(1);

  const name = `CrazyStory at ${gameID} in ${time}`;

  element.setAttribute('download', name);
  // element.download = name;
  element.click();

  window.URL.revokeObjectURL(gif);
};

const wrapText = (ctx, text, x, y, maxWidth, lineHeight) => {
  const words = text.split(' ');
  let line = '';

  for (let n = 0; n < words.length; n++) {
    const testLine = `${line + words[n]} `;
    const metrics = ctx.measureText(testLine);
    const testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      ctx.strokeText(line, x, y);
      ctx.fillText(line, x, y);
      line = `${words[n]} `;
      y += lineHeight;
    } else {
      line = testLine;
    }
  }
  ctx.strokeText(line, x, y);
  ctx.fillText(line, x, y);
};

// Calculate how many lines we need for the text
const getLines = (ctx, text, maxWidth) => {
  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const { width } = ctx.measureText(`${currentLine} ${word}`);
    if (width < maxWidth) {
      currentLine += ` ${word}`;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  return lines;
};

const loadImage = async (src) => new Promise((resolve, reject) => {
  const img = new Image();
  img.onload = () => resolve(img);
  img.onerror = reject;
  img.src = src;
});

/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {CanvasRenderingContext2D} ctx
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 5] The corner radius; It can also be an object
 * to specify different radii for corners
 * @param {Number} [radius.tl = 0] Top left
 * @param {Number} [radius.tr = 0] Top right
 * @param {Number} [radius.br = 0] Bottom right
 * @param {Number} [radius.bl = 0] Bottom left
 * @param {Boolean} [fill = false] Whether to fill the rectangle.
 * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
 */
// see https://stackoverflow.com/questions/1255512/how-to-draw-a-rounded-rectangle-using-html-canvas
function drawRoundRect(ctx, x, y, width, height, radius, fill, stroke) {
  if (typeof stroke === 'undefined') {
    stroke = true;
  }
  if (typeof radius === 'undefined') {
    radius = 5;
  }
  if (typeof radius === 'number') {
    radius = {
      tl: radius, tr: radius, br: radius, bl: radius,
    };
  } else {
    radius = {
      tl: 0, tr: 0, br: 0, bl: 0,
    };
  }

  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
}

/**
 * @typedef {Object} Layout
 * @property {number} xStart - The X Coordinate
 * @property {number} yStart - The Y Coordinate
 * @property {number} xEnd - The X Coordinate
 * @property {number} yEnd - The Y Coordinate
 */

/**
 * Draws a background rectangle with a different color
 * depending on isLeft.
 *
 * @param {CanvasRenderingContext2D} ctx
 * @param {Canvas} canvas
 * @param {Boolean} useSecondColor The color to use
 *
 * @returns {Layout} The layout object to use for other functions
 */
const prepareBackground = (ctx, canvas, useSecondColor, bgImg) => {
  // Clear everything
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // yellow background
  ctx.fillStyle = '#FFCC2A';
  ctx.drawImage(bgImg, 0, 0, canvas.width, canvas.height);

  // black border
  ctx.lineWidth = 3;
  ctx.strokeStyle = 'black';
  ctx.strokeRect(0, 0, canvas.width, canvas.height);

  // CrazyStory headline in yellow
  ctx.fillStyle = '#FFCC2A';
  ctx.strokeStyle = 'black';
  ctx.font = 'bold 40px "Bangers", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif';
  ctx.textAlign = 'center';
  ctx.lineWidth = 1;

  ctx.fillText('CrazyStories.at', canvas.width / 2, 110);
  ctx.strokeText('CrazyStories.at', canvas.width / 2, 110);

  // Rect in which the story will be
  const background = useSecondColor ? '#3298DC' : '#f14668';

  // const xStart = 15;
  // const yStart = 80;
  //
  // const height = canvas.height - PROFILE_HEIGHT * 2 - yStart - 20;
  // const width = canvas.width - 30;

  const xStart = 395;
  const yStart = 200;

  const height = 205;
  const width = 400;

  const layout = {
    xStart,
    yStart,
    xEnd: xStart + width,
    yEnd: yStart + height,
  };

  ctx.fillStyle = background;
  ctx.lineWidth = 3;
  ctx.strokeStyle = 'black';

  drawRoundRect(ctx, xStart, yStart, width, height, 6, true);

  return layout;
};

const drawProfilePic = (ctx, canvas, layout, name, icon) => {
  const PADDING = 30;

  const {
    isLeft, yEnd,
  } = layout;

  ctx.textAlign = isLeft ? 'left' : 'right';

  // const x = isLeft ? xStart + PADDING : xEnd - PROFILE_HEIGHT - PADDING;
  // const y = yEnd + PADDING + PROFILE_HEIGHT;

  const x = canvas.width / 2 - PROFILE_HEIGHT / 2;
  const y = yEnd + PADDING;

  ctx.drawImage(icon, x, y, PROFILE_HEIGHT, PROFILE_HEIGHT);

  ctx.strokeStyle = 'black';
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.arc(x + PROFILE_HEIGHT / 2, y + PROFILE_HEIGHT / 2, PROFILE_HEIGHT / 2, 0, 2 * Math.PI);
  ctx.stroke();

  ctx.fillStyle = 'black';
  ctx.font = 'bold 25px BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif';

  // const xName = isLeft ? x + PROFILE_HEIGHT + 10 : x - 10;
  ctx.textAlign = 'center';

  ctx.fillStyle = 'black';
  ctx.lineWidth = 3;

  ctx.fillText(name, x + PROFILE_HEIGHT / 2, y + PROFILE_HEIGHT + 35);
};

const drawChapter = (ctx, canvas, layout, chapter) => {
  const PADDING = 20;

  const {
    isLeft, xStart, xEnd, yStart,
  } = layout;

  const offset = isLeft ? xStart + PADDING : xEnd - PADDING;

  if (isLeft) {
    ctx.textAlign = 'left';
  } else {
    ctx.textAlign = 'right';
  }

  ctx.fillStyle = '#FFFFFF';
  ctx.strokeStyle = 'black';
  ctx.lineWidth = 3;
  ctx.font = 'medium 30px BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif';

  wrapText(ctx, chapter, offset, yStart + 50, xEnd - xStart - PADDING, 30);
};

const drawStoryOwner = (ctx, canvas, layout, owner) => {
  ctx.fillStyle = 'black';
  ctx.strokeStyle = 'black';
  ctx.lineWidth = 3;
  ctx.textAlign = 'left';
  ctx.font = 'medium 30px BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif';

  const { xStart, yStart } = layout;
  const text = `${owner}s story`.toUpperCase();

  // ctx.strokeText(text, xStart + 5, yStart - 10);
  ctx.fillText(text, xStart + 5, yStart - 10);
};

const drawCurrentRound = (ctx, canvas, layout, round, maxRounds) => {
  ctx.fillStyle = 'black';
  ctx.lineWidth = 3;
  ctx.textAlign = 'right';
  ctx.font = 'bold 20px BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif';

  const text = `${round}/${maxRounds}`;

  const { xEnd, yStart } = layout;
  ctx.fillText(text, xEnd - 5, yStart - 10);
};

const createGif = async (data) => {
  const { story, storyIndex, players } = data;

  const canvas = document.createElement('canvas');
  canvas.width = GIF_WIDTH;
  canvas.height = GIF_HEIGHT;

  const img = await loadImage(backgroundImg);

  const ctx = canvas.getContext('2d');
  ctx.fillStyle = 'white';
  // ctx.shadowBlur = 7;
  // ctx.shadowColor = 'black';

  const gif = new GIF({
    workers: 2,
    quality: 1,
    width: GIF_WIDTH,
    height: GIF_HEIGHT,
    workerScript: script,
  });

  // Async load all the needed avatars
  const imgData = [];

  for (let j = 0; j < story.length; j++) {
    const iconImg = getIcon(players[j % players.length].icon);
    imgData.push(loadImage(iconImg));
  }

  const avatars = await Promise.all(imgData);

  for (let i = 0; i < story.length; i++) {
    const chapterIndex = storyIndex - (i % players.length) + players.length;

    const { name: author } = players[chapterIndex % players.length];

    const chapter = story[i];

    const isLeft = i % 2 === 0;

    const layout = prepareBackground(ctx, canvas, isLeft, img);
    layout.isLeft = isLeft;

    drawCurrentRound(ctx, canvas, layout, i + 1, story.length);
    drawStoryOwner(ctx, canvas, layout, players[storyIndex].name);
    drawProfilePic(ctx, canvas, layout, author, avatars[i]);
    drawChapter(ctx, canvas, layout, chapter);

    const image = ctx.getImageData(0, 0, canvas.width, canvas.height);

    const duration = parseInt(story[i].split(/\W+/).length / 3, 10) * 1000;

    gif.addFrame(image, { delay: Math.max(1500, duration) });
  }

  gif.render();

  await new Promise((resolve) => {
    gif.on('finished', (blob) => {
      downloadGif(blob);
      resolve();
    });
  });
};
export default createGif;
