import React, { useRef, useEffect, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Styles
import styles from './styles.module.scss';

const ExplosionBubble = (props) => {
  const { text } = props;

  const ref = useRef();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const newHeight = ref.current ? `${ref.current.scrollWidth}px` : '0px';

    setHeight(newHeight);
  }, [ref]);

  return (
    <div id={styles.bubble} ref={ref} style={{ height }}>
      <h3 className="has-text-shadowbold is-size-3 has-text-yellow has-text-weight-bold mx-2 my-2">
        { text }
      </h3>
    </div>
  );
};

ExplosionBubble.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ExplosionBubble;
