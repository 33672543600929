import React, {
  useContext, useState, useRef, useMemo, useEffect,
} from 'react';

// Libraries
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import stats from 'analytics/matomo';

// Hooks
import { useTranslation } from 'react-i18next';

// Context
import { GameContext } from 'components/providers/GameProvider';

// Constants
import { STORY_MAX_LENGTH } from 'constants/game';

// Components
import ReadyButton from 'components/game/GameWrapper/Game/buttons/ReadyButton';
import ResetButton from 'components/game/GameWrapper/Game/buttons/ResetButton';
import ExplosionBubble from 'components/utils/ExplosionBubble';
import PlayerDisplay from '../PlayerDisplay';
import Timer from './Timer';

// Styles
import styles from './styles.module.scss';

const Round = () => {
  const {
    setChapter, chapter, givenText, toggleReady, activeRound, player, amountRounds, timer,
  } = useContext(GameContext);

  const { isReady, isAdmin } = player;

  const { t } = useTranslation(['general', 'game']);

  const [status, setStatus] = useState('');

  const animationRef = useRef();

  useEffect(() => {
    setStatus('');
  }, [activeRound]);

  const setText = (e) => {
    const text = e.target.value;

    if (text.length > 140) {
      stats.push(['trackEvent', 'Game', 'Max chapter length reached']);
      setStatus(`${t('game:error_max_length_reached')}!`);
      return;
    }

    setChapter(text);
    setStatus('');
  };

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter') return;

    if (chapter.length > 0) {
      toggleReady();
    }
  };

  const showCorrectTask = () => {
    if (activeRound === amountRounds) {
      return `${t('game:end_the_story')}!`;
    }

    if (activeRound !== 1) {
      return `${t('game:continue_the_story')}!`;
    }

    return `${t('game:write_story_beginning')}!`;
  };

  const remainingLetters = useMemo(() => {
    const surrogatePairs = chapter.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g) || [];
    const textLength = chapter.length - surrogatePairs.length;
    const lettersLeft = Math.max(0, STORY_MAX_LENGTH - textLength);

    return lettersLeft;
  }, [chapter]);

  return (
    <div className="background-image-lob is-min-fullheight is-flex flex-direction-column">
      { isAdmin && <div><ResetButton /></div>}
      {
        timer
        && (
        <div className="columns level-right mr-1">
          <div className="column">
            <Timer />
          </div>
        </div>
        )
      }
      <SwitchTransition>
        <CSSTransition
          classNames="fade"
          timeout={300}
          key={activeRound}
          nodeRef={animationRef}
        >
          <div className="columns is-centered is-vcentered has-flex-1 is-mobile">
            <div
              className="column is-9-desktop is-12-touch"
              ref={animationRef}
            >
              <div className="box has-border-black has-background-yellow is-relative">
                <div id={styles.bubble}>
                  <ExplosionBubble
                    text={`${t('game:round')}: \n ${Math.max(1, activeRound)}/${Math.max(1, amountRounds)}`}
                  />
                </div>
                <h3 className="has-text-shadowbold has-text-white has-text-weight-bold my-4 px-2 has-text-centered is-size-1-desktop is-size-2-tablet is-size-3-mobile">
                  { showCorrectTask() }
                </h3>
                {
                     activeRound > 1
                     && (
                     <p className="has-text-shadowbold py-2 px-2 mb-4 has-text-weight-semibold has-text-centered has-text-white is-size-3-desktop is-size-4-tablet is-size-5-mobile break-word">
                       {`${givenText}`}
                     </p>
                     )
                   }
                <div className="columns is-centered">
                  <div className="column is-10">
                    {
                       remainingLetters <= 20
                       && (
                       <div className="has-text-right has-text-weight-bold has-text-black">
                         <p className="mx-1">{remainingLetters}</p>
                       </div>
                       )
                     }
                    <input
                      type="text"
                      className="input boxshadow has-text-black has-height-100 has-border-black has-text-weight-bold is-size-4-desktop is-size-5-touch"
                      onKeyDown={handleKeyDown}
                      placeholder={`${t('game:placeholder_story')} `}
                      value={chapter}
                      disabled={isReady}
                      onChange={setText}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column has-text-centered">
                    <ReadyButton />
                    {
                       status
                       && (
                       <div className="has-text-centered mt-5">
                         <p
                           className="has-text-weight-bold is-size-5 has-text-black"
                         >
                           {status}
                         </p>
                       </div>
                       )
                     }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </SwitchTransition>
      <PlayerDisplay />
    </div>
  );
};

export default Round;
