import React, { useState, useEffect } from 'react';

// Libraries
import { io } from 'socket.io-client';
import PropTypes from 'prop-types';

// Hooks
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import {
  setName,
  _setGame, _clearGame, _setChapter, _clearRound, _resetGame, _addChatMsg,
} from 'store/game';

// Constants
import { LANDING } from 'constants/routes';

// Assets
import {
  readySound, nextRoundSound, storyRevealSound,
} from 'sounds';

const PLAYER_DEFAULT = {
  isAdmin: false,
  ready: false,
  id: '',
  name: '',
};

export const GameContext = React.createContext({});

const GameProvider = (props) => {
  const {
    id, players, name, playerIndex, activeRound, amountRounds, chapter, givenText, activeStory, settings, timer, chat,
  } = useSelector((state) => state.game);

  const [socket, setSocket] = useState();
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const player = players.length > 0 ? players[playerIndex] : PLAYER_DEFAULT;

  useEffect(() => {
    console.log('Calling Connect!');
    if (name.length < 3) {
      console.log(name);
      console.log('Invalid name! cya nerd');
      history.push(LANDING);
      window.location.reload();
      return null;
    }

    // TODO: improve
    if (socket) {
      setLoading(true);
      console.log('Socket already exists');
      if (!socket.connected) {
        const k = socket.connect();
        console.log(k);
      }
      setLoading(false);
      return null;
    }

    console.log('Creating new socket!');

    setLoading(true);
    console.log('Connecting');

    if (!name) {
      history.push(LANDING);
      return null;
    }
    const _socket = io(process.env.REACT_APP_API, { autoConnect: false });

    _socket.auth = { name, id };
    _socket.connect();

    _socket.onAny((event, ...args) => {
      const game = args[0];
      if (game.id) {
        window.history.replaceState(null, 'CrazyStories - Lobby', `/${game.id}`);
      }

      console.log(event, args);
    });

    _socket.on('game', (game) => {
      console.log('Game data received!');
      dispatch(_setGame(game));
    });

    _socket.on('join', (game) => {
      dispatch(_setGame(game));
    });

    _socket.on('leave', (game) => {
      dispatch(_setGame(game));
    });

    _socket.on('ready', (game) => {
      readySound.play();
      dispatch(_setGame(game));
    });

    _socket.on('game_start', (game) => {
      dispatch(_setGame(game));
    });

    // All rounds are finished, now show story
    _socket.on('game_end', (game) => {
      storyRevealSound.play();
      dispatch(_setGame(game));
    });

    _socket.on('reset', (game) => {
      dispatch(_resetGame(game));
    });

    _socket.on('round_start', (game) => {
      nextRoundSound.play();
      dispatch(_clearRound());
      dispatch(_setGame(game));
    });

    _socket.on('round_end', (game) => {
      dispatch(_setGame(game));
    });

    _socket.on('chat_msg', (msg) => {
      dispatch(_addChatMsg(msg));
    });

    _socket.on('connect', () => {
      console.log('Connected!');
      setLoading(false);
    });

    _socket.on('disconnect', () => {
      console.log('Disconnect!');

      dispatch(_clearGame());
      _socket.disconnect();
      setLoading(false);

      history.push(LANDING);
    });

    _socket.on('connect_failed', (err) => {
      console.log('Connection failed!');
      console.log(err);

      dispatch(_clearGame());
      _socket.disconnect();
      history.push(LANDING);
    });

    _socket.on('connect_error', (err) => {
      console.log('Connection Error!');
      console.log(err);

      dispatch(_clearGame());
      _socket.disconnect();
      history.push(LANDING);
    });

    console.log('Setting socket:');
    console.log(_socket);
    setSocket(_socket);

    return () => {
      console.log('Unmounting & Disconnecting...');
      _socket.disconnect();
      setSocket();
      dispatch(_clearGame());
    };
  }, []);

  const toggleReady = () => {
    const { isReady } = player;

    const newReady = !isReady;

    // if game is ongoing and user is ready, send the currently written text
    /* if (activeRound > 0 && newReady === true) {
      socket.emit('chapter_write', chapter);
      return;
    } */

    socket.emit('ready', newReady);
  };

  const startGame = () => {
    socket.emit('game_start');
  };

  const kickPlayer = (_player) => {
    socket.emit('kick_player', _player);
  };

  const { children } = props;

  const setChapter = (_chapter) => {
    socket.emit('chapter_write', _chapter);
    dispatch(_setChapter(_chapter));
  };

  const sendChatMsg = (msg) => {
    socket.emit('chat_msg', msg);
  };

  const resetGame = () => {
    socket.emit('reset_game');
  };

  const changeIcon = () => {
    socket.emit('change_icon');
  };

  const setRoundMultiplier = (amount) => {
    socket.emit('set_round_multiplier', amount);
  };

  const setTimeMultiplier = (amount) => {
    socket.emit('set_time_multiplier', amount);
  };

  const setAdmin = (newAdmin) => {
    socket.emit('set_admin', newAdmin);
  };

  const setReadStory = (newValue) => {
    socket.emit('set_read_story', newValue);
  };

  return (
    <GameContext.Provider
      value={{
        loading,
        id,

        name,
        setName,

        players,
        chat,
        toggleReady,
        startGame,
        player,
        kickPlayer,
        setAdmin,
        activeRound,

        amountRounds,

        givenText,

        chapter,
        setChapter,

        activeStory,
        resetGame,
        changeIcon,

        settings,
        setTimeMultiplier,
        setRoundMultiplier,
        setReadStory,
        sendChatMsg,

        timer,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

GameProvider.propTypes = {
  children: PropTypes.node,
};

GameProvider.defaultProps = {
  children: {},
};

export default GameProvider;
